<template>
  <v-dialog width="400" v-model="dialog" @keydown.esc="cancel">
    <v-card>
      <v-card-text min-height="100"  class="pa-4">
        <div class="text-subtitle-1">确定<span class="text-subtitle-1 red--text">撤销所有权限</span>吗？</div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="white"  @click="cancel">取消</v-btn>
        <v-btn dark color="red"  @click="agree">撤销</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'removeConfirmDlg',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
  }),
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      });
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>